import { inject } from "vue"
let cachedTenant = null

const getTenant = () => {
  if (!cachedTenant) {
    cachedTenant = inject("tenant")
  }
  return cachedTenant
}

export const JobToBuyOrder = (job, tenant = undefined) => {
  if (job?.orderBuy) return job?.orderBuy
  tenant = tenant ?? getTenant()
  job.orderBuy = job.orders?.find((order) => order.executorId === tenant?.id)
  return job.orderBuy
}

export const JobToSellOrders = (job, tenant = undefined) => {
  tenant = tenant ?? getTenant()
  if (job?.ordersSell) return job.ordersSell
  job.ordersSell = job?.orders?.filter((order) => order?.customerId === tenant?.id) || []
  return job.ordersSell
}
