import crudRouteCreation from "./crud"

export default [
  {
    path: "/",
    name: "Index",
    redirect: "/planning",
    component: () => import("../views/layouts/Authenticated.vue"),
    children: [
      {
        path: "/map",
        name: "Map",
        component: () => import("../views/pages/map/map.vue")
      },
      // ...crudRouteCreation("Orders", "orders"),
      ...crudRouteCreation("Companies", "companies"),
      ...crudRouteCreation("Drivers", "drivers"),
      ...crudRouteCreation("Locations", "locations"),
      {
        path: "/employees/calendar",
        name: "Employees.Calendar",
        component: () => import("../views/pages/drivers/calendar.vue")
      },
      {
        path: "/orders",
        name: "Orders",
        component: () => import("../views/pages/orders/orders.vue")
      },
      {
        path: "/vehicles",
        name: "Vehicles",
        component: () => import("../views/pages/vehicles/list.vue")
      },
      {
        path: "/planning",
        name: "Plan",
        component: () => import("../views/pages/planning/overview.vue")
      },
      {
        path: "/yard",
        name: "Yard",
        component: () => import("../views/pages/yard/yardPlanning.vue")
      },
      {
        path: "/reuse",
        name: "Reuse",
        component: () => import("../views/pages/reuse/overview.vue")
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("../views/pages/invoices/invoices.vue")
      },
      {
        path: "/activityReports",
        name: "activityReports",
        component: () => import("../views/pages/activity-reports/activityReports.vue")
      },
      {
        path: "/reports",
        name: "reports",
        component: () => import("../views/pages/reports/reports.vue")
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/pages/settings/settings.vue")
      },
      ...crudRouteCreation("Users", "users"),
      {
        path: "/company/profile",
        name: "Company profile",
        component: () => import("../views/pages/company-profile/details.vue")
      },
      {
        path: "/profile",
        name: "User profile",
        component: () => import("../views/pages/profile/view.vue")
      }
    ]
  },
  {
    component: () => import("../views/layouts/Authenticated.vue"),
    children: [
      {
        path: "/tmp",
        name: "Temporary",
        component: () => import("../views/pages/tmp/overview.vue")
      },
      {
        path: "/tmp/customers",
        name: "Temporary.Customers",
        component: () => import("../views/pages/tmp/customers/list.vue")
      },
      {
        path: "/tmp/customers/create",
        name: "Temporary.Customers.Create",
        component: () => import("../views/pages/tmp/customers/create.vue")
      },
      {
        path: "/tmp/customers/details/:id",
        name: "Temporary.Customers.Profile",
        component: () => import("../views/pages/tmp/customers/tenantCompanyDetails.vue")
      },
      {
        path: "/tmp/customers/edit/:id",
        name: "Temporary.Customers.Edit",
        component: () => import("../views/pages/tmp/customers/create.vue")
      },
      {
        path: "/tmp/users",
        name: "Temporary.Users",
        component: () => import("../views/pages/tmp/users/list.vue")
      },
    ]
  }
]
