import { getAuth } from "firebase/auth"
import { createRouter, createWebHistory } from "vue-router"

import { isUserInitialized } from "@/main"

import storage from "../js/storage"
import authenticatedRoutes from "./authenticated"
import authenticationRoutes from "./authentication"

const routes = [
  {
    path: "/start",
    name: "Start",
    redirect: () => (storage.getters.user.loggedIn ? { name: "Index" } : { name: "Auth" })
  },
  ...authenticationRoutes,
  ...authenticatedRoutes
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.user = new Promise(() => {})

router.beforeEach(async (to, from, next) => {
  await isUserInitialized // Wait for user initialization to complete

  const isLoggedIn = !!getAuth().currentUser?.uid
  if (
    (isLoggedIn && to.matched.filter((route) => route.name === "Auth").length === 0) ||
    (!isLoggedIn && to.matched.filter((route) => route.name === "Auth").length > 0)
  ) {
    if (to.path.toUpperCase().includes("TMP")) {
      const user = await router.user
      await user.loadingData
      if (!user.isRestored) return next({ name: "Index" })
    }
    next()
  } else if (isLoggedIn) {
    next({ name: "Index" })
  } else {
    next({ name: "Auth" })
  }
})
export default router
