import "./js/utils/mainOverrides"
import "ant-design-vue/dist/antd.css"
import "./styles/tailwind.css"
import "./styles/main.scss"
import "flag-icons/css/flag-icons.min.css"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

import { getFunctions, httpsCallable } from "@firebase/functions"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Antd from "ant-design-vue"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { createPinia } from "pinia"
import {
  AssetsService,
  AuthenticatedHttp,
  FinancesService,
  FirebaseTokenLoader,
  JobsService,
  LocationsService,
  MessagingService,
  PusherWebSocket,
  SDKObject,
  SDKObjectListener
} from "telarya-sdk"
import { createApp, reactive } from "vue"
import VueGoogleMaps from "vue-google-maps-community-fork"

import { PusherSocket } from "@/components/Other/PusherWebsocketProvider"
import { i18n } from "@/i18n"
import { getFirebaseConfig } from "@/init/firebaseConfigProvider"
import { storesInitializer } from "@/init/storesInitializer"
import { loadTheme } from "@/styles/loadTheme"

import App from "./App.vue"
import generateEndpoint from "./endpoint.js"
import { hasPermission } from "./js/Rules"
import storage from "./js/storage"
import router from "./router"

loadTheme(localStorage.getItem("theme") ?? "telarya-default-light")

// Function to switch themes
function switchTheme(themeName) {
  document.getElementById("theme-css").href = `${process.env.BASE_URL}css/${themeName}.css`
  localStorage.setItem("theme", themeName)
}
library.add(fas, fal, far)

const firebaseConfig = getFirebaseConfig()

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const firebaseFunctions = getFunctions()
const database = getFirestore()
const fileStorage = getStorage(firebaseApp)
let vueApp
const myUser = reactive({})
const tenant = reactive({})
const tenantCompany = reactive({})

// eslint-disable-next-line no-unused-vars
let userInitResolver
export let isUserInitialized = createUserInitPromise()

function createUserInitPromise() {
  return new Promise((resolve) => {
    userInitResolver = resolve
  })
}

getAuth().onAuthStateChanged(async (user) => {
  // Reset the initialization promise
  isUserInitialized = createUserInitPromise()

  Object.keys(tenant).forEach((key) => delete tenant[key])
  Object.keys(myUser).forEach((key) => delete myUser[key])

  const { default: firestoreFunctions } = await import("@/firestore")
  // if (process.env.VUE_APP_DEVELOPMENT_MODE?.toLowerCase() === "development") Pusher.logToConsole = true

  if (!vueApp) {
    // Only start the app on firebase check done;
    vueApp = createApp(App)
    vueApp.component("font-awesome-icon", FontAwesomeIcon)
    router.user = myUser
    vueApp.use(router)
    vueApp.use(createPinia())

    vueApp.mixin({
      methods: {
        isAuthorized: (key, ...args) => hasPermission(myUser, key, ...args),
        uploadFile: async function (filePath, rawFile) {
          const storageRef = ref(fileStorage, filePath)
          // Upload the file and metadata
          await uploadBytes(storageRef, rawFile)
          return await getDownloadURL(storageRef)
        },
        firedata: firestoreFunctions.firedata,
        firehttp: function (functionName, data) {
          return httpsCallable(
            firebaseFunctions,
            functionName
          )({
            ...{ language: i18n.global.locale },
            ...data
          })
        },
        log: console.log,
        $tf: (key, fallbackKey, ...params) => {
          return i18n.global.te(key) ? i18n.global.t(key, params) : i18n.global.t(fallbackKey, params)
        }
      }
    })

    vueApp.provide("firebase", firebaseApp)
    vueApp.provide("database", database)
    vueApp.provide("storage", storage)
    vueApp.provide("router", router)
    vueApp.provide("fileStorage", fileStorage)
    vueApp.provide("switchTheme", switchTheme)
    vueApp.provide("tenant", tenantCompany)
    vueApp.provide("currentUser", myUser)
    vueApp.provide("currentCompany", tenantCompany)

    vueApp.use(storage)
    // vueApp.use(fileStorage)
    vueApp.use(Antd)
    vueApp.use(i18n)
    vueApp.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        libraries: "places"
      }
    })
    vueApp.mount("#app")
  }

  if (user) {
    vueApp.unmount("#app")
    // Only start the app on firebase check done;
    vueApp = createApp(App)
    vueApp.component("font-awesome-icon", FontAwesomeIcon)
    router.user = myUser
    vueApp.use(router)
    vueApp.use(createPinia())

    vueApp.mixin({
      methods: {
        isAuthorized: (key, ...args) => hasPermission(myUser, key, ...args),
        uploadFile: async function (filePath, rawFile) {
          const storageRef = ref(fileStorage, filePath)
          // Upload the file and metadata
          await uploadBytes(storageRef, rawFile)
          return await getDownloadURL(storageRef)
        },
        firedata: firestoreFunctions.firedata,
        firehttp: function (functionName, data) {
          return httpsCallable(
            firebaseFunctions,
            functionName
          )({
            ...{ language: i18n.global.locale },
            ...data
          })
        },
        log: console.log,
        $tf: (key, fallbackKey, ...params) => {
          return i18n.global.te(key) ? i18n.global.t(key, params) : i18n.global.t(fallbackKey, params)
        }
      }
    })

    vueApp.provide("firebase", firebaseApp)
    vueApp.provide("database", database)
    vueApp.provide("storage", storage)
    vueApp.provide("router", router)
    vueApp.provide("fileStorage", fileStorage)
    vueApp.provide("switchTheme", switchTheme)
    vueApp.provide("tenant", tenantCompany)
    vueApp.provide("currentUser", myUser)
    vueApp.provide("currentCompany", tenantCompany)

    vueApp.use(storage)
    // vueApp.use(fileStorage)
    vueApp.use(Antd)
    vueApp.use(i18n)
    vueApp.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        libraries: "places"
      }
    })
    vueApp.$gmapApiPromiseLazy().then((googleMaps) => {
      const autocompleteService = new googleMaps.maps.places.AutocompleteService()
      const placesService = new googleMaps.maps.places.PlacesService(document.createElement("div"))

      vueApp.provide("autocompleteService", autocompleteService)
      vueApp.provide("placesService", placesService)
    })
    vueApp.mount("#app")
    const httpClient = new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
    const JobsServiceClient = new JobsService(generateEndpoint("jobs"), httpClient)
    const LocationsServiceClient = new LocationsService(generateEndpoint("locations"), httpClient)
    const AssetsServiceClient = new AssetsService(generateEndpoint("assets"), httpClient)
    const FinancesServiceClient = new FinancesService(generateEndpoint("finances"), httpClient)

    vueApp.provide("JobsServiceClient", JobsServiceClient)
    vueApp.provide("LocationsServiceClient", LocationsServiceClient)
    vueApp.provide("FinancesServiceClient", FinancesServiceClient)
    vueApp.provide("MessagingServiceClient", new MessagingService(generateEndpoint("messaging"), httpClient))
    vueApp.provide("AssetsServiceClient", AssetsServiceClient)
    Object.assign(tenantCompany, await AssetsServiceClient?.getTenantCompany())
    console.log("TenantCompany", tenantCompany)
    Object.assign(myUser, await AssetsServiceClient?.getMyUser())

    storesInitializer(
      tenantCompany,
      PusherSocket,
      JobsServiceClient,
      AssetsServiceClient,
      FinancesServiceClient,
      LocationsServiceClient
    )

    SDKObjectListener.WebSocket = new PusherWebSocket(PusherSocket)
    SDKObjectListener.Logging = (...msg) => console.log("SDKObjectListener", msg)
    SDKObjectListener.LocationsServiceClient = LocationsServiceClient
    SDKObjectListener.JobsServiceClient = JobsServiceClient
    SDKObject.ProxyCreator = (self) => {
      const proxy = reactive(self)
      self.proxyValueUpdater = (key, value) => {
        proxy[key] = value
      }
      return proxy
    }
    SDKObject.SetPropertyInterceptor = (self, key, value) => {
      if (self.proxyValueUpdater != null) self.proxyValueUpdater(key, value)
    }
    userInitResolver()
  } else {
    // No user is logged in, still resolve to indicate setup is complete
    userInitResolver()
  }
})
