// createSimpleStoreFactory.js
import { message } from "ant-design-vue"
import { getAuth } from "firebase/auth"
import { defineStore } from "pinia"
import { AssetsService, AuthenticatedHttp, FirebaseTokenLoader } from "telarya-sdk"

import { loadFromLocalStorage, saveToLocalStorage } from "@/components/Other/LocalStorageHelper"
import generateEndpoint from "@/endpoint"

export function createSimpleStoreFactory(
  storeObject,
  fetchFunction,
  objectClass,
  custom = {}, // <-- Additional store definitions (getters/actions/etc.)
  cacheDuration = 2 * 60 * 60 * 1000
) {
  const storeName = storeObject + "Store"

  return defineStore(storeName, {
    state: () => ({
      records: [],
      isFetching: false,
      fetchPromise: null,
      isInitialized: false,
      lastFetched: null,
      sdkService: null,
      socketService: null,
      tenantId: null,
      pusher: null
    }),
    getters: {
      /**
       * Default getters
       */
      get(state) {
        if (!this.isInitialized) {
          throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' first.`)
        }
        return state.records.filter((r) => !r.isDeleted && !r.deletedAt)
      },
      getWithDeleted(state) {
        if (!this.isInitialized) {
          throw new Error(`${storeName}: Store is not initialized. Call 'initialize()' first.`)
        }
        return state.records
      },
      ...((custom.getters ?? {}) || {}) // Merge user-defined getters
    },
    actions: {
      setTenant(tenant) {
        if (!tenant || !tenant.id) {
          throw new Error(`${storeName}: Invalid tenant provided.`)
        }
        this.tenantId = tenant.id
      },
      initializeSdkService() {
        if (this.sdkService) {
          return
        }
        this.sdkService = new AssetsService(
          generateEndpoint("assets"),
          new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
        )
      },
      subscribeToPusher() {
        if (!this.tenantId) {
          throw new Error(`${storeName}: Tenant ID is required for Pusher subscription.`)
        }
        if (!this.pusher) {
          throw new Error(`${storeName}: No webSocket service.`)
        }

        const channelName = `${this.tenantId}_${storeObject}`
        const channel = this.pusher.subscribe(channelName)

        channel.bind("Create", (data) => {
          this.logToConsole(`Pusher message received on channel ${channelName}:`, "info")
          const instance = Object.create(objectClass.prototype)
          instance.init(lowercaseKeys(data))
          this.records.push(instance)
          this.logToConsole(`Added new ${storeObject} to records:`, "info")
          console.log(instance)
        })

        channel.bind("Update", (data) => {
          this.logToConsole(`Pusher message received on channel ${channelName}:`, "info")
          const instance = Object.create(objectClass.prototype)
          instance.init(lowercaseKeys(data))

          // Find or add
          const idx = this.records.findIndex((r) => r.id === instance.id)
          if (idx !== -1) {
            this.records[idx] = instance
            this.logToConsole(`Updated existing ${storeObject} in records:`, "info")
          } else {
            this.records.push(instance)
            this.logToConsole(`Added new ${storeObject} to records:`, "info")
          }
          console.log(instance)
        })

        this.logToConsole(`Subscribed to Pusher channel: ${channelName}`, "info")
      },
      getById(id) {
        if (!this.isInitialized) {
          throw new Error(`${storeName}: Not initialized. Call 'initialize()' first.`)
        }
        return this.records.find((d) => d.id === id)
      },
      async fetch() {
        if (!this.tenantId) {
          this.logToConsole(`Tenant ID not set. Cannot fetch ${storeObject}.`, "error")
          return
        }
        if (this.isFetching) {
          return this.fetchPromise
        }

        this.isFetching = true
        this.fetchPromise = (async () => {
          try {
            this.logToConsole(`Fetching ${storeObject} for tenant ${this.tenantId}...`)
            this.initializeSdkService()
            const records = await fetchFunction(this.sdkService, this.tenantId)
            this.records = records
            this.lastFetched = Date.now()
            saveToLocalStorage(`${storeObject}-${this.tenantId}`, {
              records,
              timestamp: this.lastFetched
            })
            this.logToConsole("Updated cache/localStorage", "info")
          } catch (error) {
            this.notifyUser("Error fetching data. " + error.message, "error")
            console.error("Failed to fetch:", error)
          } finally {
            this.isFetching = false
            this.fetchPromise = null
          }
        })()
        return this.fetchPromise
      },
      initialize(tenant, webSocket) {
        if (!tenant || !tenant.id) {
          throw new Error(`${storeName}: Invalid tenant provided during init.`)
        }
        if (!webSocket) {
          throw new Error(`${storeName}: Invalid webSocket provided during init.`)
        }
        if (this.isInitialized) {
          return
        }
        this.setTenant(tenant)
        this.pusher = webSocket
        this.initializeSdkService()

        const cached = loadFromLocalStorage(`${storeObject}-${this.tenantId}`)
        if (cached) {
          const { records, timestamp } = cached
          const isCacheValid = Date.now() - timestamp < cacheDuration
          if (isCacheValid) {
            this.records = records
            this.lastFetched = timestamp
            this.logToConsole(`Loaded valid ${storeObject} for tenant ${this.tenantId} from localStorage`, "info")
          } else {
            this.logToConsole(`Cached ${storeObject} data for tenant ${this.tenantId} is stale`, "info")
          }
        }

        this.fetch()
          .then(() => this.logToConsole(`Fetched ${storeObject} for tenant ${this.tenantId}`, "info"))
          .catch((error) => console.error("Error fetching:", error))

        this.subscribeToPusher()
        this.isInitialized = true
      },
      notifyUser(msg, type = "info") {
        message.error(storeName + " " + `[${type.toUpperCase()}] ${msg}`)
      },
      logToConsole(msg, type = "info") {
        console.log(storeName + " " + `[${type.toUpperCase()}] ${msg}`)
      },
      ...(custom.actions ?? {}) // Merge user-defined actions
    }
  })
}

function lowercaseKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(lowercaseKeys)
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), lowercaseKeys(value)])
    )
  } else {
    return obj
  }
}
