// useCompaniesStore.js
import { Company } from "telarya-sdk"

import { createSimpleStoreFactory } from "@/components/Other/createSimpleStoreFactory"

export const useCompaniesStore = createSimpleStoreFactory(
  "Companies",
  (sdkService, tenantId) => sdkService.getCompaniesForTenant(tenantId),
  Company,
  {
    getters: {
      companyOptions(state) {
        console.log("Recomputing companyOptions...")
        return state.records.map((company) => ({
          label: company?.name,
          value: company?.id,
          connectId: company?.relation?.relatedCompanyMasterId,
          data: company
        }))
      },
      onlyCustomers(state) {
        return state.records.filter((c) => c.relation?.isCustomer === true)
      },
      onlyCharters(state) {
        return state.records.filter((c) => c.relation?.isExecutor === true)
      }
    },
    actions: {
      // Additional custom actions, if needed
      someCustomAction() {
        console.log("Do something extra for companies...")
      }
    }
  },
  2 * 60 * 60 * 1000 // 2 hour cache
)
