import { getAuth } from "firebase/auth"
import pako from "pako"
import { defineStore } from "pinia"
import { AuthenticatedHttp, FirebaseTokenLoader, Job, JobsService, JobStatus, SDKObject } from "telarya-sdk"
import { toRaw } from "vue"

// eslint-disable-next-line camelcase
import { HACK_JobAddBuySellOrders, HACK_JobArrayAddBuySellOrders } from "@/components/Jobs/HACK_JobAddBuySellOrders"
import generateEndpoint from "@/endpoint"

const storeObject = "Jobs"
const storeName = storeObject + "Store"

export const useJobsStore = defineStore("jobs", {
  state: () => ({
    jobsByTenant: {}, // Structure: { [tenantId]: { [rangeKey]: jobs } }
    isFetching: {}, // Structure: { [tenantId]: { [rangeKey]: true/false } }
    rangeLastFetched: {}, // Structure: { [tenantId]: { [rangeKey]: timestamp } }
    sdkService: null,
    isInitialized: false,
    tenantId: null,
    overdueJobsCount: null, // Holds overdue jobs count
    onHoldJobsCount: null, // Holds on-hold jobs count
    overdueJobsLastFetched: null, // Timestamp of the last overdue jobs fetch
    onHoldJobsLastFetched: null, // Timestamp of the last on-hold jobs fetch
    lastFetchedRanges: {}, // Tracks the last fetched range for each tenant
    lastConnectionTimestamp: null, // Timestamp of last known good connection
    isOnline: true, // Current online status
    potentialDataLoss: false // Flag to indicate potential data loss during disconnection
  }),

  actions: {
    initializeSdkService() {
      if (this.sdkService) {
        return
      }
      this.sdkService = new JobsService(
        generateEndpoint("jobs"),
        new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
      )
      this.subscribeToPusher()
      this.setupConnectionListeners()
      this.isInitialized = true
      this.lastConnectionTimestamp = Date.now()
    },

    initialize(tenant, webSocket) {
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided.`)
      }
      if (!tenant || !tenant.id) {
        throw new Error(`${storeName}: Invalid tenant provided during initialization.`)
      }
      if (!webSocket) {
        throw new Error(`${storeName}: Invalid webSocket service provided during initialization.`)
      }
      this.tenantId = tenant.id
      this.pusher = toRaw(webSocket)
    },

    setupConnectionListeners() {
      // Listen for online/offline events
      window.addEventListener("online", this.handleConnectionChange)
      window.addEventListener("offline", this.handleConnectionChange)

      // Listen for visibility change (detect wake from sleep)
      document.addEventListener("visibilitychange", this.handleVisibilityChange)
    },

    handleConnectionChange() {
      const isCurrentlyOnline = navigator.onLine
      this.logToConsole(`Connection status changed: ${isCurrentlyOnline ? "online" : "offline"}`, "info")

      // If going offline, mark potential data loss
      if (!isCurrentlyOnline && this.isOnline) {
        this.potentialDataLoss = true
      }

      // Update online status
      this.isOnline = isCurrentlyOnline
      if (isCurrentlyOnline) {
        this.lastConnectionTimestamp = Date.now()
      }
    },

    handleVisibilityChange() {
      // If document becomes visible again (potential wake from sleep)
      if (document.visibilityState === "visible") {
        const lastActive = this.lastConnectionTimestamp || 0
        const timeSinceActive = Date.now() - lastActive

        // If it's been more than 1 minute since we were last active, consider it a wake from sleep
        if (timeSinceActive > 60000) {
          this.logToConsole(
            `Device potentially woke from sleep after ${(timeSinceActive / 1000).toFixed(1)} seconds inactive`,
            "info"
          )
          this.potentialDataLoss = true
        }

        this.lastConnectionTimestamp = Date.now()
      }
    },

    async refetchRange(tenantId, startDate, endDate) {
      const rangeKey = `${startDate.toISOString()}_${endDate.toISOString()}`
      this.logToConsole(`Refetching potentially stale range: ${rangeKey} for tenant ${tenantId}`, "info")

      if (this.isFetching[tenantId]?.[rangeKey]) {
        this.logToConsole(`Range ${rangeKey} is already being fetched, skipping`, "info")
        return
      }

      try {
        // Set fetching flag
        if (!this.isFetching[tenantId]) {
          this.isFetching[tenantId] = {}
        }
        this.isFetching[tenantId][rangeKey] = true

        // Fetch fresh data
        const jobs = await this.sdkService.getJobs(undefined, startDate, endDate)
        HACK_JobArrayAddBuySellOrders(jobs, { id: tenantId })

        // Update the cache
        if (!this.jobsByTenant[tenantId]) {
          this.jobsByTenant[tenantId] = {}
        }
        this.jobsByTenant[tenantId][rangeKey] = jobs

        // Update the last fetched timestamp
        if (!this.rangeLastFetched[tenantId]) {
          this.rangeLastFetched[tenantId] = {}
        }
        this.rangeLastFetched[tenantId][rangeKey] = Date.now()

        this.logToConsole(`Successfully refetched range ${rangeKey} for tenant ${tenantId}`, "info")
      } catch (error) {
        console.error(`Failed to refetch range ${rangeKey} for tenant ${tenantId}:`, error)
      } finally {
        if (this.isFetching[tenantId]) {
          this.isFetching[tenantId][rangeKey] = false
        }
      }
    },

    isRangePotentiallyStale(tenantId, rangeKey) {
      // No timestamp for this range yet (should not happen, but being cautious)
      if (!this.rangeLastFetched[tenantId]?.[rangeKey]) {
        return true
      }

      // Range was fetched before the potential data loss occurred
      return this.potentialDataLoss && this.rangeLastFetched[tenantId][rangeKey] < this.lastConnectionTimestamp
    },

    async getJobs(tenantId, startDate, endDate) {
      this.initializeSdkService()

      if (!this.jobsByTenant[tenantId]) {
        this.jobsByTenant[tenantId] = {}
        this.isFetching[tenantId] = {}
        this.rangeLastFetched[tenantId] = {}
      }

      const rangeKey = `${startDate.toISOString()}_${endDate.toISOString()}`

      // Store the last fetched range for the tenant
      this.lastFetchedRanges[tenantId] = { startDate, endDate }

      // Check if we have cached data
      if (this.jobsByTenant[tenantId][rangeKey]) {
        // Check if this range is potentially stale due to sleep/disconnection
        if (this.isRangePotentiallyStale(tenantId, rangeKey)) {
          this.logToConsole(
            `Range ${rangeKey} for tenant ${tenantId} may be stale. Serving cached data and refreshing in background.`,
            "info"
          )

          // Return cached data immediately but schedule a background refresh
          setTimeout(() => {
            this.refetchRange(tenantId, startDate, endDate)
          }, 0)
        }

        return this.jobsByTenant[tenantId][rangeKey]
      }

      // If we're already fetching this range, return empty array
      if (this.isFetching[tenantId][rangeKey]) {
        return []
      }

      this.isFetching[tenantId][rangeKey] = true

      try {
        const jobs = await this.sdkService.getJobs(undefined, startDate, endDate)
        HACK_JobArrayAddBuySellOrders(jobs, { id: tenantId })

        this.jobsByTenant = {
          ...this.jobsByTenant,
          [tenantId]: {
            ...this.jobsByTenant[tenantId],
            [rangeKey]: jobs
          }
        }

        // Store the fetch timestamp
        if (!this.rangeLastFetched[tenantId]) {
          this.rangeLastFetched[tenantId] = {}
        }
        this.rangeLastFetched[tenantId][rangeKey] = Date.now()

        return jobs
      } catch (error) {
        console.error(`Failed to fetch jobs for tenant ${tenantId}, range ${rangeKey}:`, error)
        return []
      } finally {
        this.isFetching[tenantId][rangeKey] = false
      }
    },

    subscribeToPusher() {
      if (!this.tenantId) {
        throw new Error(`${storeName}: Tenant ID is required for Pusher subscription.`)
      }

      if (!this.pusher) {
        throw new Error(`${storeName}: No webSocket service.`)
      }

      const channelName = `${this.tenantId}_${storeObject}`
      const channel = toRaw(this.pusher).subscribe(channelName)

      channel.bind("Create", async (data) => {
        this.logToConsole(`Pusher message received on channel ${channelName} (Create):`, "info")

        let instance

        if (data.compressedData) {
          try {
            // Decompress the data
            const compressedBytes = Uint8Array.from(atob(data.compressedData), (c) => c.charCodeAt(0))
            const decompressedBytes = pako.inflate(compressedBytes)
            const decompressedJson = new TextDecoder("utf-8").decode(decompressedBytes)
            const parsedJson = lowercaseKeys(JSON.parse(decompressedJson))
            instance = new Job()
            instance.init(parsedJson)
            instance = SDKObject.ProxyCreator(instance)
            console.log("Decompressed job instance:", instance)
          } catch (error) {
            this.logToConsole(`Failed to decompress data: ${error.message}`, "error")
            return
          }
        } else {
          // Fetch the full job instance using the job ID from the message
          instance = await this.sdkService.getJob(data.id)
        }

        HACK_JobAddBuySellOrders(instance, { id: this.tenantId })
        // Check if the job has datesAll
        if (Array.isArray(instance.datesAll) && instance.datesAll.length > 0) {
          // Convert datesAll to Date objects
          const jobDates = instance.datesAll.map((date) => new Date(date))

          for (const [tenantId, ranges] of Object.entries(this.jobsByTenant)) {
            for (const [rangeKey, jobs] of Object.entries(ranges)) {
              const [start, end] = rangeKey.split("_").map((date) => new Date(date))

              // Check if any date in datesAll falls within the current range
              const isDateInRange = jobDates.some((jobDate) => jobDate >= start && jobDate <= end)

              if (isDateInRange) {
                // Add the job to the correct rangeKey if it doesn't already exist
                if (!this.jobsByTenant[tenantId][rangeKey].some((job) => job.id === instance.id)) {
                  this.jobsByTenant[tenantId][rangeKey] = [...this.jobsByTenant[tenantId][rangeKey], instance]
                  this.logToConsole(`Added new job to tenant ${tenantId}, range ${rangeKey}.`, "info")
                }
              }
            }
          }
        }

        // Update connection timestamp since we received a Pusher message
        this.lastConnectionTimestamp = Date.now()
        // Clear potential data loss flag since we're getting updates
        this.potentialDataLoss = false
        console.log(instance)
      })

      channel.bind("Delete", async (data) => {
        this.logToConsole(`Pusher message received on channel ${channelName} (Delete):`, "info")

        // Check if the job has datesAll
        if (Array.isArray(data.datesAll) && data.datesAll.length > 0) {
          // Convert datesAll to Date objects
          const jobDates = data.datesAll.map((date) => new Date(date))

          for (const [tenantId, ranges] of Object.entries(this.jobsByTenant)) {
            for (const [rangeKey, jobs] of Object.entries(ranges)) {
              const [start, end] = rangeKey.split("_").map((date) => new Date(date))

              // Check if any date in datesAll falls within the current range
              const isDateInRange = jobDates.some((jobDate) => jobDate >= start && jobDate <= end)

              if (isDateInRange) {
                // Find the index of the job to delete
                const jobIndex = this.jobsByTenant[tenantId][rangeKey].findIndex((job) => job.id === data.id)

                if (jobIndex !== -1) {
                  // Remove the job from the array
                  this.jobsByTenant[tenantId][rangeKey].splice(jobIndex, 1)
                  this.logToConsole(`Deleted job from tenant ${tenantId}, range ${rangeKey}.`, "info")
                }
              }
            }
          }
        }

        // Update connection timestamp since we received a Pusher message
        this.lastConnectionTimestamp = Date.now()
        // Clear potential data loss flag since we're getting updates
        this.potentialDataLoss = false
      })

      channel.bind("Update", async (data) => {
        this.logToConsole(`Pusher message received on channel ${channelName} (Update):`, "info")

        let updatedJob

        if (data.compressedData) {
          try {
            const compressedBytes = Uint8Array.from(atob(data.compressedData), (c) => c.charCodeAt(0))
            const decompressedBytes = pako.inflate(compressedBytes)
            const decompressedJson = new TextDecoder("utf-8").decode(decompressedBytes)
            const parsedJson = lowercaseKeys(JSON.parse(decompressedJson))
            updatedJob = new Job()
            updatedJob.init(parsedJson)
            updatedJob = SDKObject.ProxyCreator(updatedJob)
            console.log("Decompressed updated job instance:", updatedJob)
          } catch (error) {
            this.logToConsole(`Failed to decompress update data: ${error.message}`, "error")
            return
          }
        } else {
          // Fetch the full updated job instance using the job ID from the message
          updatedJob = await this.sdkService.getJob(data.id)
        }

        // Apply any additional job modifications (if needed)
        HACK_JobAddBuySellOrders(updatedJob, { id: this.tenantId })

        // Update the job across all date ranges
        for (const [tenantId, ranges] of Object.entries(this.jobsByTenant)) {
          for (const [rangeKey, jobs] of Object.entries(ranges)) {
            const [startStr, endStr] = rangeKey.split("_")
            const start = new Date(startStr)
            const end = new Date(endStr)
            const jobDates = Array.isArray(updatedJob.datesAll) ? updatedJob.datesAll.map((date) => new Date(date)) : []
            // Check if any date in the job falls within this range
            const isInRange = jobDates.some((jobDate) => jobDate >= start && jobDate <= end)

            // Remove the job from this range if it no longer belongs
            const existingIndex = jobs.findIndex((job) => job.id === updatedJob.id)
            if (existingIndex !== -1 && !isInRange) {
              this.jobsByTenant[tenantId][rangeKey].splice(existingIndex, 1)
              this.logToConsole(`Removed job ${updatedJob.id} from tenant ${tenantId}, range ${rangeKey}.`, "info")
            }
            // Add the job to this range if it should be here but isn't already
            if (isInRange && existingIndex === -1) {
              this.jobsByTenant[tenantId][rangeKey] = [...this.jobsByTenant[tenantId][rangeKey], updatedJob]
              this.logToConsole(`Added updated job ${updatedJob.id} to tenant ${tenantId}, range ${rangeKey}.`, "info")
            }
          }
        }

        // Update connection timestamp since we received a Pusher message
        this.lastConnectionTimestamp = Date.now()
        // Clear potential data loss flag since we're getting updates
        this.potentialDataLoss = false
      })

      // Add connection status event listener
      channel.bind("pusher:subscription_succeeded", () => {
        this.logToConsole(`Successfully subscribed to Pusher channel: ${channelName}`, "info")
        this.lastConnectionTimestamp = Date.now()
        // Clear potential data loss flag since we're connected
        this.potentialDataLoss = false
      })

      channel.bind("pusher:subscription_error", (error) => {
        this.logToConsole(`Error subscribing to Pusher channel ${channelName}: ${error}`, "error")
        // Set potential data loss flag since we couldn't connect
        this.potentialDataLoss = true
      })

      // Handle disconnection events
      this.pusher.connection.bind("disconnected", () => {
        this.logToConsole("Pusher disconnected", "info")
        this.potentialDataLoss = true
      })

      this.pusher.connection.bind("connected", () => {
        this.logToConsole("Pusher connected", "info")
        this.lastConnectionTimestamp = Date.now()
        // Don't clear potentialDataLoss here as we might have missed updates while disconnected
      })

      this.logToConsole(`Subscribed to Pusher channel: ${channelName}`, "info")
    },

    async refreshLastFetchedRange() {
      if (!this.tenantId || !this.lastFetchedRanges[this.tenantId]) {
        console.warn("No last fetched range available to refresh.")
        return
      }

      const { startDate, endDate } = this.lastFetchedRanges[this.tenantId]
      this.logToConsole(
        `Refreshing last fetched range for tenant ${
          this.tenantId
        }: ${startDate.toISOString()} - ${endDate.toISOString()}`
      )

      try {
        const refreshedJobs = await this.getJobs(this.tenantId, startDate, endDate)
        const rangeKey = `${startDate.toISOString()}_${endDate.toISOString()}`
        this.jobsByTenant[this.tenantId][rangeKey] = refreshedJobs
        this.logToConsole(`Successfully refreshed jobs for tenant ${this.tenantId}.`, "info")
      } catch (error) {
        console.error("Failed to refresh last fetched range:", error)
      }
    },

    logToConsole(m, type = "info") {
      console.log(storeName + " " + `[${type.toUpperCase()}] ${m}`)
    },

    async fetchOverdueJobsCount() {
      const now = new Date()
      if (
        this.overdueJobsLastFetched &&
        now - this.overdueJobsLastFetched < 12 * 60 * 60 * 1000 // 12 hours
      ) {
        return this.overdueJobsCount
      }

      try {
        this.overdueJobsCount = await this.sdkService.getOverdueJobsCount()
        this.overdueJobsLastFetched = now
      } catch (error) {
        console.error("Failed to fetch overdue jobs count:", error)
      }
      return this.overdueJobsCount
    },

    async fetchOnHoldJobsCount() {
      const now = new Date()
      if (
        this.onHoldJobsLastFetched &&
        now - this.onHoldJobsLastFetched < 3 * 60 * 60 * 1000 // 3 hours
      ) {
        return this.onHoldJobsCount
      }

      try {
        this.onHoldJobsCount = await this.sdkService.getJobsCount([JobStatus.OnHold])
        this.onHoldJobsLastFetched = now
      } catch (error) {
        console.error("Failed to fetch on-hold jobs count:", error)
      }
      return this.onHoldJobsCount
    },

    // Clean up event listeners when store is no longer needed
    cleanup() {
      window.removeEventListener("online", this.handleConnectionChange)
      window.removeEventListener("offline", this.handleConnectionChange)
      document.removeEventListener("visibilitychange", this.handleVisibilityChange)
    }
  }
})

function lowercaseKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(lowercaseKeys)
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.charAt(0).toLowerCase() + key.slice(1), lowercaseKeys(value)])
    )
  } else {
    return obj
  }
}
